import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ImageDataLike } from 'gatsby-plugin-image'

import Container from 'components/shared/container'
import { Heading } from 'components/shared/typography'
import LazyImage from 'components/shared/lazy-image'
import Button from 'components/shared/button'
import Modal from 'components/shared/modal'

import PopupContactForm from 'components/popup-contact-form'

import useBreakpoint from 'hooks/useBreakpoint'

import media from 'styles/media'

const StyledWrapper = styled.div`
  position: relative;
  margin-bottom: 80px;

  ${media.lg.min} {
    margin-bottom: 100px;
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  img {
    object-position: 0% 97%;
  }
`

const StyledContentWrapper = styled(Container)`
  padding-top: 80px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;

  ${media.lg.min} {
    flex-direction: row;
    padding-top: 145px;
    padding-bottom: 145px;
  }
`

const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-top: 40px;

  button {
    width: 100%;
  }

  ${media.lg.min} {
    margin-top: 0;
    padding-right: 70px;
    justify-content: flex-end;

    button {
      width: fit-content;
    }
  }
`

const StyledModalFormWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.jet};

  ${media.lg.min} {
    padding: 60px 80px;
    width: 800px;
    height: auto;
  }
`

const StyledContactFormHeading = styled.h2`
  font-size: 22px;
  text-transform: uppercase;
  color: white;

  margin-bottom: 0;
  padding: 30px 0 0 36px;

  ${media.lg.min} {
    font-size: 30px;
    padding: 0;
    margin-bottom: 60px;
  }
`

type Props = {
  image: {
    src: ImageDataLike
    alt: string
  }
  heading: string
  buttonText: string
}

const ActionBox: React.FC<Props> = ({ image, heading, buttonText }) => {
  const [modalOpened, setModalOpened] = useState(false)

  const closeModal = () => {
    setModalOpened(false)
  }

  useEffect(() => {
    const bodyElem = document.documentElement
    const bodyWidth = bodyElem.getBoundingClientRect().width
    if (modalOpened) {
      bodyElem.style.width = `${bodyWidth}px`
      bodyElem.style.overflow = 'hidden'
    }
    return () => {
      bodyElem.style.width = 'auto'
      bodyElem.style.overflow = 'auto'
    }
  }, [modalOpened])

  const { lg } = useBreakpoint()

  return (
    <StyledWrapper>
      <Background>
        <LazyImage src={image?.src} alt={image?.alt} fill />
      </Background>
      <StyledContentWrapper>
        <Heading
          dangerouslySetInnerHTML={{ __html: heading }}
          as="h3"
          weight={700}
          line={lg ? '55px' : '45px'}
          margin="0"
          size={lg ? 30 : 26}
          sizeDiff={0}
        />
        <StyledButtonWrapper>
          <Button onClick={() => setModalOpened(true)}>{buttonText}</Button>
        </StyledButtonWrapper>
      </StyledContentWrapper>
      <Modal active={modalOpened} onClose={closeModal} closeable>
        <StyledModalFormWrapper>
          <StyledContactFormHeading>Napisz do nas</StyledContactFormHeading>
          <PopupContactForm />
        </StyledModalFormWrapper>
      </Modal>
    </StyledWrapper>
  )
}

export default ActionBox
