import React from 'react'
import styled, { css } from 'styled-components'

import Container from 'components/shared/container'

import media from 'styles/media'

import close from 'assets/icons/close-small.svg'

const StyledContainer = styled(Container)`
  ${media.lg.max} {
    padding: 0;
  }
`

const StyledFilters = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  padding: 0 36px;

  ${media.lg.min} {
    grid-template-columns: repeat(auto-fill, 216px);
    gap: 20px;
    padding: 0;
  }
`
const StyledFilterItem = styled.div<{ active?: boolean }>`
  height: 50px;
  font-size: 14px;

  position: relative;
  border-radius: 70px;
  border: 2px solid ${({ theme }) => theme.colors.pewterblue};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.pewterblue};
  white-space: nowrap;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s ease-out;

  img {
    display: none;

    ${media.lg.min} {
      display: block;
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      transition: 0.3s ease-out;
    }
  }

  ${media.lg.min} {
    width: 216px;
    height: 60px;
    font-size: 18px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.pewterblue};
      color: ${({ theme }) => theme.colors.white};
    }
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.pewterblue};
      color: ${({ theme }) => theme.colors.white};

      &:hover {
        img {
          opacity: 1;
        }
      }
    `}
`

type Props = {
  filters: string[]
  activeCategories: string[]
  setActiveCategories: React.Dispatch<React.SetStateAction<string[]>>
}

const Filters: React.FC<Props> = ({
  filters,
  activeCategories,
  setActiveCategories,
}) => {
  const allFilters = ['Wszystkie', ...filters]

  const toggleOption = (value: string) => {
    if (value === 'Wszystkie') {
      setActiveCategories(['Wszystkie'])
      return
    }
    if (activeCategories.includes(value)) {
      const newCategories = activeCategories.filter((el) => el !== value)
      setActiveCategories(
        newCategories.length === 0 ? ['Wszystkie'] : newCategories
      )
      return
    }
    setActiveCategories([
      ...activeCategories.filter((el) => el !== 'Wszystkie'),
      value,
    ])
  }

  return (
    <StyledContainer>
      <StyledFilters>
        {allFilters.map((el, index) => (
          <StyledFilterItem
            key={`${el}-${index}`}
            onClick={() => toggleOption(el)}
            active={activeCategories.includes(el)}
          >
            {el}
            <img src={close} alt="" />
          </StyledFilterItem>
        ))}
      </StyledFilters>
    </StyledContainer>
  )
}

export default Filters
