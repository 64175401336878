import React, { useState } from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

import Header from 'components/header'
import Breadcrumbs from 'components/shared/breadcrumbs'
import Filters from 'components/filters'
import Rooms from 'components/pages/rooms/rooms'
import ActionBoxContact from 'components/action-box-contact'

import { PomieszczeniaPageQuery } from 'types/graphql'

const Pomieszczenia: React.FC<PageProps<PomieszczeniaPageQuery>> = ({
  data,
}) => {
  const PAGE = data?.wpPage?.roomsPage
  const PAGE_SEO = data?.wpPage?.seo!

  const ALL_ROOMS =
    data?.allWpRoomArticle?.nodes?.map((room) => ({
      image: {
        src: room?.featuredImage?.node?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: room?.featuredImage?.node?.altText!,
      },
      name: room?.title!,
      slug: room?.slug!,
    })) || []

  const ALL_FILTERS = ALL_ROOMS?.map(({ name }) => name) || []

  const [activeRooms, setActiveRooms] = useState(['Wszystkie'])

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />

      <Header
        image={{
          src: PAGE?.roomsPageS1Img?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.roomsPageS1Img?.altText!,
        }}
        title={PAGE?.roomsPageS1Title!}
        subtitle={PAGE?.roomsPageS1Paragraph!}
        page="pomieszczenia"
      />
      <Breadcrumbs crumbs={[{ name: 'Pomieszczenia' }]} />
      <Filters
        filters={ALL_FILTERS}
        activeCategories={activeRooms}
        setActiveCategories={setActiveRooms}
      />
      <Rooms
        rooms={ALL_ROOMS?.filter(
          ({ name }) =>
            activeRooms.includes(name) || activeRooms.includes('Wszystkie')
        )}
      />
      <ActionBoxContact
        image={{
          src: PAGE?.roomsPageS3Img?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.roomsPageS3Img?.altText!,
        }}
        heading={PAGE?.roomsPageS3Paragraph!}
        buttonText={PAGE?.roomsPageS3ButtonText!}
      />
    </Layout>
  )
}

export default Pomieszczenia

export const query = graphql`
  query PomieszczeniaPage {
    wpPage(slug: { regex: "/pomieszczenia/" }) {
      seo {
        ...WpSEO
      }
      roomsPage {
        roomsPageS1Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        roomsPageS1Title
        roomsPageS1Paragraph
        roomsPageS3Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        roomsPageS3ButtonText
        roomsPageS3Paragraph
      }
    }
    allWpRoomArticle {
      nodes {
        title
        slug
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1000)
              }
            }
          }
        }
      }
    }
  }
`
