import React from 'react'
import styled, { css } from 'styled-components'

import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazy-image'

import { scrollToNextSibling } from 'utils/scrollToSection'

import media from 'styles/media'

import arrowDown from 'assets/icons/arrow-down.svg'

import { ImageDataLike } from 'gatsby-plugin-image'

import homedenLogo from 'assets/icons/homeden.svg'

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;

  ${media.lg.min} {
    display: flex;
    align-items: center;
  }
`

const Background = styled.div<{ page?: PageType }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  img {
    ${({ page }) =>
      page === 'strona główna' &&
      css`
        object-position: 20% 0%;
        ${media.lg.min} {
          object-position: 0% 35%;
        }
      `}

    ${({ page }) =>
      page === 'o nas' &&
      css`
        object-position: 0% 0%;
        ${media.lg.min} {
          object-position: 0% 100%;
        }
      `}

    ${({ page }) =>
      page === 'blog' &&
      css`
        object-position: 20% 0%;
        ${media.lg.min} {
          object-position: 0% 50%;
        }
      `}

    ${({ page }) =>
      page === 'pomieszczenia' &&
      css`
        object-position: 55% 0%;
        ${media.lg.min} {
          object-position: 0% 40%;
        }
      `}

    ${({ page }) =>
      page === 'współpraca' &&
      css`
        object-position: 60% 0%;
        ${media.lg.min} {
          object-position: 0% 17%;
        }
      `}

      ${({ page }) =>
      page === 'kontakt' &&
      css`
        object-position: 0% 0%;
        ${media.lg.min} {
          object-position: 0% 50%;
        }
      `}
  }
`

const StyledInnerWrapper = styled.div<{ page?: PageType }>`
  display: flex;
  flex-direction: column;
  padding-top: 200px;

  ${media.lg.min} {
    padding-top: 0;
    width: 50%;
    margin-bottom: ${({ page }) => (page === 'kontakt' ? '300px' : '0')};
  }
`

const StyledDiscoverButton = styled.button`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  width: 138px;
  height: 138px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.25);

  &:before {
    content: '';
    position: absolute;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    width: 113px;
    height: 113px;
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    background-color: rgba(255, 255, 255, 0.35);
    border-radius: 50%;
    width: 89px;
    height: 89px;
    z-index: -1;
    transition-duration: 0.3s;
    transition-timing-function: ease;
  }

  ${media.lg.min} {
    /* display this button above overlay on contact page */
    z-index: 1;

    &:hover {
      &:after {
        width: 138px;
        height: 138px;
      }
    }
  }

  ${media.lg.max} {
    width: 88px;
    height: 88px;
    bottom: 40px;
    background-color: rgba(255, 255, 255, 0.25);

    &:after {
      width: 63px;
      height: 63px;
      background-color: rgba(255, 255, 255, 0.35);
    }

    &:before {
      display: none;
    }
  }
`

const HeadingWithIconWrapper = styled.div`
  display: flex;
  flex-direction: column;

  img {
    margin-top: 20px;
    margin-bottom: 40px;
    width: 151px;
    height: 43px;
  }

  ${media.lg.min} {
    flex-direction: row;
    align-items: center;

    h4 {
      margin: 0;
    }

    img {
      margin: 0;
      margin-left: 37px;
      width: 237px;
      height: 68px;
    }
  }
`

const StyledHeading = styled.h4`
  font-size: 30px;
  margin-bottom: 20px;

  ${media.lg.min} {
    font-size: 45px;
    margin-bottom: 35px;
  }
`

const StyledText = styled.h2`
  font-size: 18px;
  font-weight: normal;
  font-family: 'Open Sans';

  ${media.lg.min} {
    font-size: 24px;
  }
`

const StyledIcon = styled.img`
  width: 13px;

  ${media.lg.min} {
    width: 20px;
  }
`

type PageType =
  | 'strona główna'
  | 'kontakt'
  | 'o nas'
  | 'blog'
  | 'współpraca'
  | 'pomieszczenia'

type HeaderProps = {
  image: {
    src: ImageDataLike
    alt: string
  }
  title: string
  subtitle: string
  page?: PageType
}

const Header: React.FC<HeaderProps> = ({ image, title, subtitle, page }) => {
  const headingAsH1 = page === 'blog' || page === 'kontakt'

  return (
    <StyledWrapper id="header">
      <Background page={page}>
        <LazyImage src={image?.src} alt={image?.alt} fill loading="eager" />
      </Background>
      <Container>
        <StyledInnerWrapper page={page}>
          {page === 'o nas' ? (
            <>
              <HeadingWithIconWrapper>
                <StyledHeading
                  dangerouslySetInnerHTML={{ __html: 'Jesteśmy częścią' }}
                />
                <img src={homedenLogo} />
              </HeadingWithIconWrapper>
            </>
          ) : (
            <>
              <StyledHeading
                as={headingAsH1 ? 'h1' : 'h4'}
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <StyledText dangerouslySetInnerHTML={{ __html: subtitle }} />
            </>
          )}
        </StyledInnerWrapper>
      </Container>
      <StyledDiscoverButton
        onClick={() => scrollToNextSibling('#header')}
        aria-label="zjedź niżej"
      >
        <StyledIcon src={arrowDown} alt="strzałka w dół" />
      </StyledDiscoverButton>
    </StyledWrapper>
  )
}

export default Header
