import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import media from 'styles/media'

import useOutsideClick from 'hooks/useOutsideClick'

import closeIcon from 'assets/icons/exit.svg'

import { ActiveRequired } from 'types/shared/active'

type Props = {
  children: any
  closeable?: boolean
  onClose?: () => void
} & ActiveRequired

const Overlay = styled.div<ActiveRequired>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 400;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  overflow-y: scroll;
  justify-content: center;
  transition: 0.3s;
  transition-property: opacity, visibility;
  opacity: 0;
  visibility: hidden;
  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  ${media.lg.min} {
    align-items: center;
  }
`

const Content = styled.div<ActiveRequired>`
  position: relative;
  max-width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  visibility: hidden;
  transform: translate(0, 60px);
  transition: 0.35s ease;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0) !important;
    `}

  min-height: 100vh;

  ${media.lg.min} {
    min-height: unset;
  }
`

const Close = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 5;

  ${media.lg.min} {
    top: 31px;
    right: 31px;
  }
`

const StyledCloseButton = styled.button`
  background-color: transparent;
  padding: 0;
  border: none;
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const Modal: React.FC<Props> = ({ children, active, closeable, onClose }) => {
  const [opened, setOpened] = useState<boolean>(active ?? false)
  const modalRef = useRef(null)
  useEffect(() => {
    setOpened(active)
  }, [active])

  const closeModal = () => {
    setOpened(false)
    if (onClose) {
      onClose()
    }
  }

  if (closeable) {
    //   eslint-disable-next-line
    useOutsideClick({
      ref: modalRef,
      handler: closeModal,
      condition: opened,
    })
  }

  // useEffect(() => {
  //   const bodyElem = document.documentElement
  //   const bodyWidth = bodyElem.getBoundingClientRect().width
  //   if (opened) {
  //     bodyElem.style.width = `${bodyWidth}px`
  //     bodyElem.style.overflow = 'hidden'
  //   }
  //   return () => {
  //     bodyElem.style.width = 'auto'
  //     bodyElem.style.overflow = 'auto'
  //   }
  // }, [opened])

  return (
    <Overlay active={opened}>
      <Content active={opened} ref={modalRef}>
        {closeable && (
          <Close>
            <StyledCloseButton
              onClick={() => {
                closeModal()
              }}
            >
              <img src={closeIcon} alt="" />
            </StyledCloseButton>
          </Close>
        )}
        {children}
      </Content>
    </Overlay>
  )
}

export default Modal
