import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Formik, Field, Form, FormikHelpers } from 'formik'
import axios from 'axios'

import Button from 'components/shared/button'

import Input from 'components/input'
import Checkbox from 'components/checkbox'

import { CONTACT_SCHEMA, CONTACT_INIT_VALUES } from 'constants/forms'

import media from 'styles/media'

import type { Values } from 'types/shared/form'

const StyledContactForm = styled.div`
  position: relative;
  padding: 40px 36px;

  ${media.lg.min} {
    padding: 0;

    form div:first-child,
    form div:nth-child(2) {
      margin-bottom: 30px;
    }
  }

  form {
    margin-bottom: 0;
  }
`

const StyledTermsWrapper = styled.div`
  padding: 35px 0 60px;

  p {
    color: ${({ theme }) => theme.colors.white};
  }

  button {
    color: ${({ theme }) => theme.colors.white};
  }

  label {
    margin-bottom: 25px;
  }

  ${media.lg.min} {
    padding: 30px 0 15px;
  }
`

const StyledTermsErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.danger} !important;
  font-size: 14px;
`

const StyledFormMessage = styled.p<{ error?: boolean }>`
  margin: 20px 0;
  font-size: 24px;
  line-height: 33px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.success};
  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.colors.danger};
    `};
`

const ContactForm: React.FC = () => {
  const [formInfo, setFormInfo] = useState({
    text: '',
    error: false,
  })

  const ref = useRef(null)

  return (
    <StyledContactForm>
      <Formik
        innerRef={ref}
        initialValues={CONTACT_INIT_VALUES}
        validationSchema={CONTACT_SCHEMA}
        onSubmit={(
          values,
          { setSubmitting, resetForm }: FormikHelpers<Values>
        ) => {
          if (formInfo.text && !formInfo.error) return
          ;(async () => {
            try {
              setSubmitting(true)

              const query = `
              mutation {
                sendContactForm(
                  input: { name: "${values.name}",  email: "${values.email}", message: "${values.message}", agree1: ${values.agree1}}
                ) {
                  code
                  message
                  status
                  response {
                    message
                  }
                }
              }
              `

              const data = await axios.post(
                `https://systemelfa.indigital.guru/_graphql`,
                {
                  query,
                }
              )

              // if (data.data.data.sendContactForm.code !== 200)
              //   throw new Error(
              //     data.data.data.sendContactForm.response[0].message
              //   )

              setSubmitting(false)
              setFormInfo({
                text: 'Wiadomość została wysłana. Odpowiemy na nią najszybciej jak to możliwe.',
                error: false,
              })

              setTimeout(() => {
                setFormInfo({ text: '', error: false })
                resetForm()
              }, 5000)
            } catch (err) {
              console.log(err)
              setSubmitting(false)
              setFormInfo({
                text: err.message || 'Formularz nie został wysłany',
                error: true,
              })
              setTimeout(() => {
                setFormInfo({ text: '', error: false })
              }, 5000)
            }
          })()
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <Field
              name="name"
              label="Imię i nazwisko"
              component={Input}
              required
            />

            <Field
              name="email"
              label="Adres e-mail"
              component={Input}
              required
            />

            <Field
              name="message"
              label="Wiadomość"
              textarea
              component={Input}
              required
            />

            <StyledTermsWrapper>
              <Field
                type="checkbox"
                name="agree1"
                label="Wyrażam zgodę na przetwarzanie moich danych osobowych podanych w powyższym formularzu w celu odpowiedzi na zapytanie przez INDIGITAL WE TRUST SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ, ul. Targowa 67 lok. 2,
                03-729 Warszawa oraz przez podmioty trzecie."
                component={Checkbox}
              />

              {Boolean(touched.agree1 && errors.agree1) && (
                <StyledTermsErrorMessage>
                  {errors.agree1}
                </StyledTermsErrorMessage>
              )}
            </StyledTermsWrapper>

            {formInfo.text && (
              <StyledFormMessage error={formInfo.error}>
                {formInfo.text}
              </StyledFormMessage>
            )}

            <Button
              disabled={!!(formInfo.text && !formInfo.error)}
              loading={isSubmitting}
            >
              Wyślij
            </Button>
          </Form>
        )}
      </Formik>
    </StyledContactForm>
  )
}

export default ContactForm
