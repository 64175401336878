import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Link } from 'gatsby'

import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazy-image'

import slugify from 'utils/slugify'

import media from 'styles/media'

import { ImageDataLike } from 'gatsby-plugin-image'

const StyledContainer = styled(Container)`
  padding: 0 0 80px 0;

  ${media.lg.min} {
    padding: 0 30px 100px 30px;
  }
`

const StyledRoomCardsWrapper = styled.div`
  display: grid;
  grid-gap: 30px;
  margin-top: 40px;

  ${media.lg.min} {
    margin-top: 80px;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 570px;
    grid-gap: 20px;
  }
`

const reveal = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
`

const StyledRoomCard = styled.div<{ filtered?: boolean; tall?: boolean }>`
  position: relative;
  cursor: pointer;
  overflow: hidden;

  visibility: hidden;
  opacity: 0;
  animation: ${reveal} 0.7s forwards;

  img {
    transition: transform 0.3s ease, opacity 0.25s linear !important;
    height: 360px;

    ${media.lg.min} {
      height: 100%;
    }
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }

  ${({ tall, filtered }) =>
    tall &&
    !filtered &&
    css`
      grid-row: span 2;
    `}
`

const StyledBadge = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-family: 'Merriweather';
  font-weight: 700;
  border-radius: 50px;
  text-transform: capitalize;

  padding: 16px 0;
  font-size: 18px;
  bottom: 30px;
  width: 193px;

  ${media.lg.min} {
    bottom: 80px;
    width: 240px;
    font-size: 24px;
    padding: 19px 0;
  }
`

type Room = {
  image: {
    src: ImageDataLike
    alt: string
  }
  name: string
  slug: string
}

type Props = {
  rooms: Room[]
}

const RoomsFilters: React.FC<Props> = ({ rooms }) => {
  return (
    <StyledContainer>
      <StyledRoomCardsWrapper>
        {rooms.map(({ image, name }, index) => (
          <StyledRoomCard
            key={`${index}-${name}`}
            as={Link}
            to={`${slugify(name)}/`}
            tall={(index + 1) % 2 !== 0}
            filtered={true}
          >
            <LazyImage src={image.src} alt={image.alt} fill />
            <StyledBadge>{name}</StyledBadge>
          </StyledRoomCard>
        ))}
      </StyledRoomCardsWrapper>
    </StyledContainer>
  )
}

export default RoomsFilters
