import React from 'react'
import styled, { css } from 'styled-components'
import { FieldProps } from 'formik'

type InputProps = {
  readOnly: boolean
  textarea: boolean
  invalid: boolean
  valid: boolean
  as: keyof JSX.IntrinsicElements
  type: 'text'
}

const InputItem = styled.div<{ disabled: boolean }>`
  position: relative;
  width: 100%;
  overflow: hidden;
  font-size: 15px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

  &:nth-of-type(1),
  &:nth-of-type(2) {
    margin-bottom: 60px;
  }
`

const Label = styled.label<{ invalid: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  color: ${({ theme }) => theme.colors.white};
  pointer-events: none;
  white-space: nowrap;
  transition: 0.2s;
  span {
    color: ${({ theme }) => theme.colors.white};
  }
  ${({ invalid }) =>
    invalid &&
    css`
      color: ${({ theme }) => theme.colors.danger};
    `}
`

const StyledInput = styled.input<InputProps>`
  width: 100%;
  padding-top: 25px;
  color: ${({ theme }) => theme.colors.white};
  height: ${({ textarea }) => (textarea ? '125px' : '53px')};
  background: transparent;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};

  transition: 0.3s;
  resize: none;
  outline: 0;
  &:focus {
    border-color: ${({ theme, valid }) =>
      valid ? theme.colors.success : theme.colors.light};
  }

  ${({ invalid }) =>
    invalid &&
    css`
      border-color: ${({ theme }) => theme.colors.danger} !important;
      transition: none;

      &:focus {
        color: ${({ theme }) => theme.colors.white};
        transition: 0.2s;
      }
    `}
`

type Props = {
  label: string
  textarea?: boolean
  required?: boolean
  disabled?: boolean
  readonly?: boolean
  formID?: string
}

const FormInput: React.FC<Props & FieldProps> = ({
  field: { name, onChange, onBlur, value },
  form: { touched, errors },
  label,
  textarea,
  required,
  disabled,
  readonly,
}) => {
  const labelText = ((errors[name] && touched[name] && errors[name]) ||
    (required ? `${label}<span>*</span>` : label)) as string

  const invalid = !!(touched[name] && errors[name] && required)
  const valid = !!(touched[name] && !errors[name] && required)

  return (
    <InputItem disabled={!!disabled}>
      <StyledInput
        id={name}
        name={name}
        type="text"
        as={textarea ? 'textarea' : 'input'}
        textarea={!!textarea}
        placeholder=" "
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        invalid={invalid}
        valid={valid}
        disabled={disabled}
        readOnly={!!readonly}
      />
      <Label
        htmlFor={name}
        invalid={invalid}
        dangerouslySetInnerHTML={{ __html: labelText }}
      />
    </InputItem>
  )
}

export default FormInput
